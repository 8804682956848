/**!
 * HSC ATAR Calculator
 * By Daniel15 - 2011-2019
 * Feel free to use any of this, but please link back to my site (dan.cx)
 */

import '../../css/main.css';

import React from 'react';
import ReactDOM from 'react-dom';

import {initAnalytics} from '../analyticsLogger';
import CalculatorHSC from '../components/CalculatorHSC';
import CalculatorContainer from '../containers/CalculatorContainer';
import Data from '../../data/hsc.json';
import {State} from '../constants';

ReactDOM.render(
  <CalculatorContainer
    appConfig={{
      ...Data,
      alertMessage: null,
      bugReportMessage: (
        <>
          Please report any errors by emailing{' '}
          <strong>
            <a href="mailto:%63%61l&#99;%40at%61r%6e%6ftes%2e&#99;om">
              calc@atarnotes.com
            </a>
          </strong>
          .
        </>
      ),
      resourcesURL: 'https://atarnotes.com/hsc-resources-atar-notes/',
      showSuggestedCourses: true,
      state: State.HSC,
    }}
    calculator={CalculatorHSC}
  />,
  document.getElementById('calculator'),
);

initAnalytics(State.HSC);
